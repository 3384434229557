import useSoundLibrary from "use-sound";
import { soundSetting as serviceSoundSetting } from "service";
import { SoundBackground, Win6to10Sound } from "assets/audio";

export const useSound = (soundEnabled, musicEnabled) => {
  const soundSetting = {
    ...serviceSoundSetting,
    soundEnabled
  };

  const [play6to7WinSound] = useSoundLibrary(Win6to10Sound, soundSetting);
  const useBackgroundSound = () => { return useSoundLibrary(SoundBackground, {volume: 0.1, loop: true}) };
  const useBackgroundSoundAuto = () => { return useSoundLibrary(SoundBackground, {autoplay: true, volume: 0.1, loop: true}) };

  const [ playSoundBackground, { pause: stopSoundBackground } ] = musicEnabled ? useBackgroundSoundAuto() : useBackgroundSound();

  return {
    play6to7WinSound,
    playSoundBackground,
    stopSoundBackground,
  }
}

export default useSound;